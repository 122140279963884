// We import the CSS which is extracted to its own file by esbuild.
// Remove this line if you add a your own CSS build pipeline (e.g postcss).

// If you want to use Phoenix channels, run `mix help phx.gen.channel`
// to get started and then uncomment the line below.
// import "./user_socket.js"

// You can include dependencies in two ways.
//
// The simplest option is to put them in assets/vendor and
// import them using relative paths:
//
//     import "../vendor/some-package.js"
//
// Alternatively, you can `npm install some-package --prefix assets` and import
// them using a path starting with the package name:
//
//     import "some-package"
//

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html"
// Establish Phoenix Socket and LiveView configuration.
import {Socket} from "phoenix"
import {LiveSocket} from "phoenix_live_view"
import topbar from "../vendor/topbar"

let Hooks = {}

Hooks.DatePick = {
  mounted() {
    console.log("DP")

    console.log(this.el.value)
    this.el.addEventListener("change", e => {
      console.log(e)
      console.log(this.el.value)
      // if(this.el.value == ""){
      //   this.el.value = "ABC"
      // }
    })
    // this.el.addEventListener("click", e => {
    //   console.log("click")
    //   if(this.el.type == "date") {
    //   // console.log("to text")
    //   //   this.el.type = "text"
    //     this.el.readOnly = true
    //   }
    //   else {
    //   console.log("to date")
    //     this.el.type = "date"
    //     this.el.showPicker()
    //     this.el.readOnly = false
    //   }
    // })
  }
}

Hooks.FBShare = {
  mounted() {
    var url = this.el.dataset.url;
    document.getElementById('fbshare').onclick = function() {
      FB.ui({
        display: 'popup',
        method: 'share',
        href: url,
      }, function(response){});
    }
  }
}

Hooks.SvgObject = {
  mounted() {
    console.log("SVG mounted")

    o = this

    var videoModal = document.getElementById('video-modal');
    var videoPlayer = document.getElementById('video-player');
    var videoSource = document.getElementById('video-source');
    var svgObject = document.getElementById('svg_object').contentDocument;
    var elements = svgObject.getElementsByClassName('video-link');


    svgObject.querySelectorAll('.video-link').forEach(element => {
      element.addEventListener("click", e => {
        console.log("HELLO")

        var video = element.dataset.video
        console.log(video)

        videoPlayer.pause()
        videoSource.setAttribute('src', "/images/"+video+".mp4");
        videoPlayer.load()

        // videoModal.style.display = "flex";

        o.pushEvent("open_video_modal", {"video": video})

        // videoPlayer.play();
      })
    })
  },
  updated() {
    console.log("UUUUUPPPPP")
  }
}

Hooks.CopyLink = {
  mounted() {
    this.el.addEventListener('click', function(e) {
      var url = this.dataset.url
      if (typeof(navigator.clipboard)=='undefined') {
        var textArea = document.createElement('textarea');
        textArea.value = url;
        textArea.style.position='fixed';  //avoid scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
            console.log('Was not possible to copy te text: ', err);
        }

        document.body.removeChild(textArea)
        return;
      }
      else{
        navigator.clipboard.writeText(url);
      }
    })
  }
}

Hooks.ToggleDetails = {
  mounted() {
    this.el.addEventListener("click", e => {
      var id = this.el.dataset.details
      var obj = document.getElementById(`details_${id}`)
      var arrow = this.el.firstChild
      console.log(arrow)
      obj.classList.toggle('hidden');
      arrow.classList.toggle('rotate-180');
    })
  }
}

Hooks.Slider = {
  mounted() {
    var slider = document.getElementById("slider")
    var sliderPrev = document.getElementById("slider-prev")
    var sliderNext = document.getElementById("slider-next")
    var currentSlide = document.querySelector("#slider .block")
    var slides = document.querySelectorAll("#slider .slide")
    var currentIndex = Array.prototype.indexOf.call(slides, currentSlide)
    var numberOfSlide = slides.length
    sliderNext.addEventListener("click", e => {
      currentSlide = document.querySelector("#slider .block")
      currentIndex = Array.prototype.indexOf.call(slides, currentSlide)
      currentIndex++
      if(currentIndex >= numberOfSlide) {
        currentIndex = 0
      }

      for (var i = 0; i < numberOfSlide; i++) {
        slides[i].classList.remove('block')
        slides[i].classList.add('hidden')
      }

      var slide = slides[currentIndex]
      slide.classList.add('block')
      slide.classList.remove('hidden')
    })

    sliderPrev.addEventListener("click", e => {
      currentSlide = document.querySelector("#slider .block")
      currentIndex = Array.prototype.indexOf.call(slides, currentSlide)
      currentIndex--
      if(currentIndex < 0) {
        currentIndex = numberOfSlide - 1
      }

      for (var i = 0; i < numberOfSlide; i++) {
        slides[i].classList.remove('block')
        slides[i].classList.add('hidden')
      }

      var slide = slides[currentIndex]
      slide.classList.add('block')
      slide.classList.remove('hidden')
    })

    setInterval(nextSlide, 5000)

    function nextSlide() {
      currentSlide = document.querySelector("#slider .block")
      currentIndex = Array.prototype.indexOf.call(slides, currentSlide)
      currentIndex++
      if(currentIndex >= numberOfSlide) {
        currentIndex = 0
      }

      for (var i = 0; i < numberOfSlide; i++) {
        slides[i].classList.remove('block')
        slides[i].classList.add('hidden')
      }

      var slide = slides[currentIndex]
      slide.classList.add('block')
      slide.classList.remove('hidden')
    }
  }
}

let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {hooks: Hooks, params: {_csrf_token: csrfToken}})

// Show progress bar on live navigation and form submits
// topbar.config({barColors: {0: "#29d"}, shadowColor: "rgba(0, 0, 0, .3)"})
// window.addEventListener("phx:page-loading-start", info => topbar.show())
// window.addEventListener("phx:page-loading-stop", info => topbar.hide())


// Show progress bar on live navigation and form submits
topbar.config({barColors: {0: "#29d"}, shadowColor: "rgba(0, 0, 0, .3)"})
let topBarScheduled = undefined;
window.addEventListener("phx:page-loading-start", () => {
  if(!topBarScheduled) {
    topBarScheduled = setTimeout(() => topbar.show(), 200);
  };
});
window.addEventListener("phx:page-loading-stop", () => {
  clearTimeout(topBarScheduled);
  topBarScheduled = undefined;
  topbar.hide();
});

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
liveSocket.enableDebug()
// liveSocket.enableLatencySim(10000)  // enabled for duration of browser session
liveSocket.disableLatencySim()
window.liveSocket = liveSocket

